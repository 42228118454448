<template>
  <a
    class="flex items-center justify-center mx-auto justify-items-center"
    href="https://www.trustpilot.com/review/colonia.tech"
    target="_blank"
  >
    <div v-if="isExtended" class="hidden mr-4 text-base md:block">
      {{ $t('trustPilotComponent.customersSay') }}
    </div>
    <div
      v-if="!isFunnelVersion"
      class="mr-4 text-lg font-medium"
      :class="{ 'md:text-white': isHeroVersion }"
    >
      {{ $t(`trustPilotComponent.${label}`) }}
    </div>

    <div
      v-if="isExtended || isFunnelVersion || isHeroVersion || isLogoVisible"
      class="flex"
    >
      <div v-if="!isHeroVersion && !isLogoVisible" class="hidden mr-4 md:block">
        <nuxt-img
          :src="`/trustpilot/stars_${starsImage}.svg`"
          class="w-auto h-6"
          alt=""
          format="webp"
        />
      </div>
      <div v-if="showEvaluation" class="mr-4 text-cblack">
        <span class="font-medium">{{ score }}</span>
        {{ $t('trustPilotComponent.outOfFive') }}
      </div>

      <div v-if="isLogoVisible">
        <nuxt-img
          src="/trustpilot/logo.svg"
          class="w-auto h-5"
          alt="Trustpilot"
          format="webp"
        />
      </div>
      <div
        v-if="isHeroVersion"
        class="bg-[url('/public/trustpilot/logo.svg')] md:bg-[url('/public/trustpilot/logo_white.svg')] h-6 w-24 bg-center bg-no-repeat"
      ></div>
    </div>
  </a>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
  isExtended: {
    type: Boolean,
    default: false,
  },
  isFunnelVersion: {
    type: Boolean,
    default: false,
  },
  isHeroVersion: {
    type: Boolean,
    default: false,
  },
  showEvaluation: {
    type: Boolean,
    default: true,
  },
  isLogoVisible: {
    type: Boolean,
    default: true,
  },
  score: {
    type: Number,
    required: true,
    validator(value: number) {
      return value >= 1 && value <= 5
    },
  },
})

const pointFiveRoundedScore = Math.round(props.score * 2) / 2
const starsImage = `${pointFiveRoundedScore}`.replace('.', '_')

const label = computed(() => {
  switch (pointFiveRoundedScore) {
    case 1:
    case 1.5:
      return 'bad'
    case 2:
    case 2.5:
      return 'poor'
    case 3:
    case 3.5:
      return 'average'
    case 4:
      return 'great'
    default:
      return 'excellent'
  }
})
</script>
